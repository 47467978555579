import mixpanel from 'mixpanel-browser';

// Get the Mixpanel token from the environment variable
let mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

// Print the token value
console.log('Mixpanel Token:', mixpanelToken);

// Replace with "EMPTY_TOKEN" if the token is undefined, null, or an empty string
if (!mixpanelToken) {
  mixpanelToken = 'EMPTY_TOKEN';
}

// Initialize Mixpanel with the token
mixpanel.init(mixpanelToken, {
  debug: process.env.NODE_ENV !== 'production',
  opt_out_tracking_by_default: process.env.NODE_ENV === 'testing'
});
