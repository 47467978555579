import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useGrowthBook } from '@growthbook/growthbook-react';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from './containers/Navbar';
import Footer from './components/Footer';
import Routes from './routes/Routes';

import styles from './style/containers/App.module.scss';
import { getIntroClass } from './actions/sessions';
import { setSentryUser } from './setup/sentry';

const URLS_TO_HIDE_FOOTER = ['onboarding', 'assignment', 'impersonate'];
const URLS_TO_HIDE_NAVBAR = ['onboarding', 'welcome'];

const App = ({ getIntroClassAction, orderInfo, token, user }) => {
  const location = useLocation();
  const history = useHistory();
  const growthBook = useGrowthBook();

  useEffect(() => {
    getIntroClassData({ user, orderInfo });
  }, []);

  useEffect(() => {
    if (user?.id) setSentryUser(user);
  }, [user?.id]);

  useEffect(() => {
    if (user) {
      growthBook.setAttributes({
        userId: user.id,
        isLoggedIn: !!user
        // other relevant user attributes
      });
    } else {
      growthBook.setAttributes({
        userId: null,
        isLoggedIn: false
      });
    }
  }, [user, growthBook]);

  const getIntroClassData = ({ user, orderInfo }) => {
    if (!user?.token || !orderInfo?.active_orders) return;
    getIntroClassAction({ token: user?.token, languageId: orderInfo?.active_orders[0]?.order_appointment_types[0]?.language_id });
  };

  const renderHeader = () => {
    if (!token) return <div className={styles.emptyNavbar}></div>;
    if (URLS_TO_HIDE_NAVBAR.some((url) => location.pathname.includes(url))) return <div className={styles.emptyNavbar}></div>;
    return <Navbar history={history} />;
  };

  const renderFooter = () => {
    if (!token) return;
    if (URLS_TO_HIDE_FOOTER.some((url) => location.pathname.includes(url))) return;
    return <Footer user={user} />;
  };

  return (
    <div className={styles.app}>
      {renderHeader()}
      <main className={styles.main}>
        <Routes history={history} userType={user?.user_type} />
      </main>
      {renderFooter()}
    </div>
  );
};

App.defaultProps = {
  token: '',
  user: {}
};

App.propTypes = {
  token: PropTypes.string,
  user: PropTypes.object,
  productTypes: PropTypes.array,
  orderInfo: PropTypes.object,
  getIntroClassAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  token: state.user?.token,
  user: state.user,
  orderInfo: state.orderInfo,
  courses: state.courses
});

export default withRouter(
  connect(mapStateToProps, {
    getIntroClassAction: getIntroClass
  })(App)
);
